import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { CSNclTabControlMetadata, TabStyle, UpdateTabControl, UpdateVRTabControl } from "../../common/communication.base";
import { NclBaseTabControl, NclVRTabControl } from "../../common/components.ncl";
import { AcquireControl, StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2VRTabControl from "./K2VRTabControl";
import K2TabControlBase from "./K2TabControlBase";
import K2ListDetailTabControl from "./K2ListDetailTabControl";
import K2ResponsiveTabControl from "./K2ResponsiveTabControl";
import { writeToCSS } from "../VCX/VCXHelper";
import { flushSync } from "react-dom";
import { useAnimationEnd } from "../hooks";

export interface TabControlPropsBase<T> {
  control: T;
  currentPage: string;
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export type TabControlProps = TabControlPropsBase<NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl>>;

const K2TabControl = (props: WithContextPlacementProps & { isVRTab: boolean }) => {
  const control = useRef(
    AcquireControl(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclBaseTabControl) as NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl>
  );
  const [data, setData] = useState<UpdateTabControl>(control.current.init({ updateState: updateState, updateVCX: updateVCX }) as UpdateTabControl);
  const [VCXVersion, setVCXVersion] = useState(-1);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [renderMobileMenu, handleAnimationEnd] = useAnimationEnd(showMobileMenu);
  const element = useRef(null);

  useEffect(() => {
    return () => {
      control.current.willUnMount(true);
    };
  }, []);

  useEffect(() => {
    control.current.afterUpdateState();
  }, [data]);

  function updateState(state: UpdateTabControl) {
    if ((state as UpdateVRTabControl).AddedTabs) {
      // vynucuje re-render po updatu, aby se nainicializoval listener pro viewrealizer a vyvolala se metoda openModal
      // jinak se stavalo, ze po reconnectu neslo napr. zavrit modalni okno
      // po reconnectu prijdou vsechny zpravy najednou a react neprovadi re-render za kazdym serverovym updatem komponent
      flushSync(() => {
        setData(state);
      });
    } else {
      setData(state);
    }

    if (element.current && control.current.VCX !== control.current.Parent.VCX) {
      writeToCSS(control.current.VCX, element.current);
    }
  }

  function isHorizontal() {
    if (control.current.Ncl.FrgtData.TabStyle === TabStyle.ftsUpTab || control.current.Ncl.FrgtData.TabStyle === TabStyle.ftsDownTab) return true;
    else return false;
  }

  function updateVCX(vcxVersion: number) {
    setVCXVersion(vcxVersion);

    if (element.current) {
      writeToCSS(control.current.VCX, element.current);
    }
  }

  if (!data) return null;

  return (
    <div ref={element} style={StyleHelper(control.current, props.style)}>
      {props.isVRTab && (
        <K2VRTabControl
          control={control.current as NclVRTabControl}
          currentPage={data.CurrentPage}
          setShowMobileMenu={setShowMobileMenu}
          mainMenuDataVersion={(data as UpdateVRTabControl).MainMenuDataVersion}
        />
      )}
      {!props.isVRTab && isHorizontal() && <K2TabControlBase control={control.current} currentPage={data.CurrentPage} setShowMobileMenu={setShowMobileMenu} />}
      {!props.isVRTab && !isHorizontal() && (
        <K2ListDetailTabControl control={control.current} currentPage={data.CurrentPage} setShowMobileMenu={setShowMobileMenu} />
      )}
      {renderMobileMenu && (
        <K2ResponsiveTabControl
          control={control.current}
          currentPage={data.CurrentPage}
          showMobileMenu={showMobileMenu}
          setShowMobileMenu={setShowMobileMenu}
          handleAnimationEnd={handleAnimationEnd}
        />
      )}
    </div>
  );
};

export default K2TabControl;
